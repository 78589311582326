<template>
	<div>
		<pui-datatable v-if="!masterDetail" :modelName="modelName" :modelColumnDefs="modelColumnDefs" :navigableDetail="false"></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			readOnly
			:showCreateBtn="false"
			:showDeleteBtn="false"
			:navigableDetail="false"
		></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'equipamientoagenteaduana-grid',
	components: {},
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'equipamientoagenteaduana',
			modelColumnDefs: {}
		};
	}
};
</script>
